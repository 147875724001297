<template>
  <div class="data-collection-main">
    <div class="data-collection-main-search">
      <div class="search-tabs">
        <span
          :class="['baidu', { active: tabIndex == 0 }]"
          @click="tabIndex = 0"
        >
          <img
            :src="require('assets/images/dataCollection/baidu-logo.png')"
            alt=""
          />
        </span>
        <span
          :class="['three60', { active: tabIndex == 1 }]"
          @click="tabIndex = 1"
        >
          <img :src="require('assets/images/dataCollection/360.png')" alt="" />
        </span>
        <span
          :class="['bing', { active: tabIndex == 2 }]"
          @click="tabIndex = 2"
        >
          <img :src="require('assets/images/dataCollection/log.png')" alt="" />
        </span>
      </div>
      <div class="input-wrap">
        <el-input
          v-model="searchValue"
          placeholder="请选择搜索引擎，或者输入一个网址"
          @keydown.native="enterSearch($event)"
        />
        <div class="search-btn" @click="handleSearch">
          <!-- <button ></button> -->
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="data-collection-main-quicks">
      <div
        class="quicks-item"
        v-for="item in quickList"
        :key="item.url"
        @click="locationQuick(item.url)"
      >
        <div class="quicks-item-picbg">
          <img :src="item.imgUrl" alt="" />
        </div>
        <span class="title">{{ item.name }}</span>
      </div>
    </div>
    <!--添加快捷方式弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="addQuickDialog"
      :title="quickDialogTitle"
      class="quicks"
    >
      <div class="content">
        <div class="input-wrap">
          <span class="title">名称</span>
          <el-input v-model="addQuickName" ref="quickName" />
        </div>
        <div class="input-wrap">
          <span class="title">网址</span>
          <el-input v-model="addQuickUrl" />
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <button class="cancel" @click="cancelSetQuick">取消</button>
          <button
            :class="['sure', !enableQuick ? 'disable' : '']"
            @click.stop="pushQuick"
          >
            确定
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ajax from "utils/xhr";
import { save, list, del } from "api/dataCollection";
import disablemenu from "./contextmenumixin";
export default {
  name: "DataCollectionMain",
  mixins: [disablemenu],
  data() {
    return {
      //搜索tabs索引
      tabIndex: 0,
      //搜索内容
      searchValue: "",
      //快捷方式集合
      quickList: [
        {
          name: "人民日报",
          imgUrl: require("assets/images/dataCollection/icom1.png"),
          url: "http://paper.people.com.cn",
        },
        {
          name: "人民网",
          imgUrl: require("assets/images/dataCollection/icom2.png"),
          url: "http://www.people.com.cn",
        },
        {
          name: "新华网",
          imgUrl: require("assets/images/dataCollection/icom3.png"),
          url: "http://xinhuanet.com",
        },
        {
          name: "国务院",
          imgUrl: require("assets/images/dataCollection/icom4.png"),
          url: "http://www.gov.cn",
        },
        {
          name: "全国人大",
          imgUrl: require("assets/images/dataCollection/icom5.png"),
          url: "http://www.npc.gov.cn",
        },
        {
          name: "全国政协",
          imgUrl: require("assets/images/dataCollection/icom6.png"),
          url: "http://www.cppcc.gov.cn",
        },
        {
          name: "中纪委",
          imgUrl: require("assets/images/dataCollection/icom7.png"),
          url: "https://www.ccdi.gov.cn",
        },
        {
          name: "最高法",
          imgUrl: require("assets/images/dataCollection/icom8.png"),
          url: "https://www.court.gov.cn",
        },
        {
          name: "最高检",
          imgUrl: require("assets/images/dataCollection/icom9.png"),
          url: "https://www.spp.gov.cn",
        },
        {
          name: "今日头条",
          imgUrl: require("assets/images/dataCollection/icom10.png"),
          url: "https://www.toutiao.com",
        },
        // {
        //   name: "搜狐",
        //   imgUrl: require("assets/images/dataCollection/icom11.png"),
        //   url: "https://www.sohu.com",
        // },
        // {
        //   name: "网易",
        //   imgUrl: require("assets/images/dataCollection/icom12.png"),
        //   url: "https://www.163.com",
        // },
      ],
      //默认快捷方式图片
      defaultQuickImg: require("assets/images/dataCollection/quick.png"),
      //显示添加/修改快捷方式
      addQuickDialog: false,
      //快捷方式数量是否到达限制
      isQuickOver: false,
      //快捷方式名称
      addQuickName: "",
      //快捷方式地址
      addQuickUrl: "",
      //切换快捷方式弹窗标题
      quickDialogTitle: "",
      //当前修改的快捷方式索引
      currentQuickIndex: -1,
    };
  },
  computed: {
    enableQuick() {
      return !!(
        this.addQuickName &&
        this.addQuickUrl &&
        this.addQuickName.replace(/\s*/, "") &&
        this.addQuickUrl.replace(/\s*/, "")
      );
    },
  },
  methods: {
    /**
     * 点击更多按钮
     * @param { Number } inx 快捷方式索引
     */
    setQuicks(inx) {
      this.currentQuickIndex = inx;
      this.quickList.forEach((i, x) => {
        if (x === inx) {
          i.menuPopup = !i.menuPopup;
        } else {
          i.menuPopup = false;
        }
      });
    },
    //点击添加快捷方式
    handleAddQuick() {
      this.quickDialogTitle = "添加快捷方式";
      this.addQuickDialog = true;
      this.$nextTick(() => {
        this.$refs["quickName"].focus();
      });
    },
    //点击确定添加/修改快捷方式按钮
    pushQuick() {
      if (this.enableQuick) {
        if (this.quickDialogTitle == "添加快捷方式") {
          //添加
          ajax({
            url: "http://creword.com/notifiedClient/getQuickTitleAndIco",
            params: {
              url: this.addQuickUrl,
            },
          });
        } else {
          //修改
          this.modify();
          this.findQuickList();
        }
      }
    },
    /**
     * 点击修改快捷方式
     * @param { Number } inx 快捷方式索引
     */
    modifyQuick(inx) {
      this.quickDialogTitle = "修改快捷方式";
      this.addQuickName = this.quickList[inx].name;
      this.addQuickUrl = this.quickList[inx].url;
      this.quickList[this.currentQuickIndex].menuPopup = false;
      this.addQuickDialog = true;
    },
    /**
     * 移除快捷方式
     * @param { Number } inx 快捷方式索引
     */
    async removeQuick(inx) {
      const params = {
        id: this.quickList[inx].id,
      };
      const res = await del(params);
      if (res.code == 200) {
        this.findQuickList();
      }
    },
    /**
     * 保存快捷方式到服务器
     * @param { String } title winf传过来的title
     * @param { String } ico winf传过来的ico地址
     */
    async saveQuick(title, ico) {
      const params = {
        keyword: this.addQuickName,
        uri: this.addQuickUrl,
        type: 3,
        img: ico,
      };
      try {
        await save(params);
      } catch (e) {
        console.log(e.name + e.message);
      }
      this.cancelSetQuick();
    },
    /**
     * 修改快捷方式
     */
    async modify() {
      const params = {
        keyword: this.addQuickName,
        uri: this.addQuickUrl,
        type: 3,
        id: this.quickList[this.currentQuickIndex].id,
      };
      try {
        await save(params);
      } catch (e) {
        console.log(e.name + e.message);
      }
      this.cancelSetQuick();
    },
    /**
     * 查询快捷方式列表
     */
    async findQuickList() {
      const form = new FormData();
      form.append("type", 3);
      const res = await list(form);
      if (res.code == 200) {
        const data = res.data;
        this.quickList = [];
        data.forEach((item) => {
          this.quickList.push({
            name: item.keyword,
            url: item.uri,
            imgUrl: item.img,
            id: item.id,
            menuPopup: false,
          });
        });
        if (!this.quickList.length) {
          this.currentQuickIndex = -1;
        }
        if (this.quickList.length < 10) {
          this.isQuickOver = false;
        }
        if (this.quickList.length == 10) {
          this.isQuickOver = true;
        }
      }
    },
    /**
     * 导航快捷方式
     * @param { Number } inx 快捷方式对应的索引
     */
    locationQuick(inx) {
      window.ShowDownLoadTips();
      // this.searchValue = inx;
      // this.handleSearch();
    },
    cancelSetQuick() {
      this.addQuickName = "";
      this.addQuickUrl = "";
      this.addQuickDialog = false;
    },
    //点击搜索
    handleSearch() {
      // window.ShowDownLoadTips();

      const reg =
        /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;
      let url = "";
      if (!reg.test(this.searchValue)) {
        switch (this.tabIndex) {
          case 0:
            url = "https://www.baidu.com/s?&wd=";
            break;
          case 1:
            url = "https://www.so.com/s?&q=";
            break;
          case 2:
            url = "https://cn.bing.com/search?q=";
        }
        url += this.searchValue;
      } else {
        url = this.searchValue;
      }
      // window.location.href = url
      this.$parent.urlInput = url;
      setTimeout(() => {
        this.$parent.locationSend();
      }, 100);
    },
    //回车搜索
    enterSearch(e) {
      const key = e.keyCode || e.which;
      if (key === 13) {
        this.handleSearch();
      }
    },
    docClickHandler() {
      if (this.currentQuickIndex != -1) {
        this.quickList[this.currentQuickIndex].menuPopup = false;
      }
    },
  },
  created() {
    this.findQuickList();
  },
  mounted() {
    //获取快捷方式
    window.getTitleAndIco = async (title, ico) => {
      console.log(`获取快捷方式图标 : \n
      title : ${title}\nicon : ${ico}`);
      await this.saveQuick(title, ico);
      this.findQuickList();
    };
    document.addEventListener("click", this.docClickHandler.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("click", this.docClickHandler);
  },
};
</script>

<style lang="scss" scoped>
.data-collection-main {
  height: 700px;
  padding: 1px 70px 0;
  &-search {
    margin-top: 100px;
    .search-tabs {
      @include flex-start;
      > span {
        @include flex-center;
        @include noselect;
        position: relative;
        top: 1px;
        width: 128px;
        height: 38px;
        background: #f7f7f7;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;
        &:first-child ~ span {
          margin-left: 5px;
        }
      }
      .baidu {
        img {
          width: 86px;
          height: 22px;
        }
      }
      .three60 {
        img {
          width: 86px;
          height: 23px;
        }
      }
      .bing,
      .baidu {
        img {
          width: 59px;
          // height: 24px;
        }
      }
      > span.active {
        border: 1px solid #ff6900;
        background-color: #fff;
        &::before {
          content: "";
          position: absolute;
          top: 38px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
      }
    }
    .input-wrap {
      @include flex-start;
      border: 1px solid #ff6900;
      border-radius: 0 3px 3px 3px;
      height: 100%;
      ::v-deep .el-input {
        flex: 1;
        input {
          border: none;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          &:focus {
            border: none;
          }
        }
        .el-input__inner::-webkit-input-placeholder {
          /* 使用webkit内核的浏览器 */
          // color: #e0484b;
          line-height: 40px;
        }
      }
      .search-btn {
        height: 40px;
        padding: 0 10px;
        background: #ff6900;
        // @include backgroundGroup("~assets/images/idiom.png");
        @include flex-center();
        // border-radius: 5px;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }
  }
  &-quicks {
    @include flex-start;
    flex-wrap: wrap;
    // margin: 50px auto 0;
    // width: 460px;
    .quicks-item {
      @include flex-center(column);
      @include noselect;
      justify-content: normal;
      position: relative;
      width: 92px;
      height: 92px;
      padding: 15px 10px 10px;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      &.add-quick .title {
        white-space: nowrap;
        overflow: visible;
        padding: 0;
      }
      &-picbg {
        @include flex-center;
        flex-shrink: 0;
        width: 34px;
        height: 34px;
        background: #f1f3f4;
        border-radius: 12px;
        img {
          width: 30px;
          height: 26px;
        }
      }
      .title {
        margin-top: 10px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        max-width: 60px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .sets {
        @include flex-center;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 5px;
        right: 2px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        transition: opacity 0.5s;
        &:hover {
          background-color: rgba(32, 33, 36, 0.2);
        }
        .sets-icon {
          position: relative;
          width: 18px;
          height: 18px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("~assets/images/dataCollection/quick_more.svg")
              no-repeat;
            background-size: 100%;
          }
        }
      }
      .sets-menu {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        position: absolute;
        display: none;
        top: 30px;
        right: 15px;
        width: 128px;
        padding: 8px 0;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
        &-item {
          min-height: 32px;
          line-height: 32px;
          white-space: nowrap;
          padding: 0 24px;
          font-size: 14px;
          font-weight: 500;
          color: #333;
          &:hover {
            background-color: rgba(189, 193, 198, 0.5);
          }
        }
        &.sets-menu-active {
          display: flex;
        }
      }
      &:hover {
        background-color: rgba(32, 33, 36, 0.1);
      }
      &:hover .sets {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  //添加快捷方式弹窗
  .quicks {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 20vh !important;
      width: 423px;
      height: 258px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      border-radius: 6px;
      .el-dialog__header {
        padding: 10px 10px;
        background-color: #f4f6f7;
        line-height: 1;
        border-radius: 6px;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 15px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px 20px;
        .content {
          flex: 1;
          .input-wrap {
            &:last-child {
              margin-top: 20px;
            }
            .title {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }
            .el-input {
              margin-top: 8px;
              input {
                height: 30px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;
              }
            }
          }
        }
        .footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          .footer-btns {
            @include flex-start;
            button {
              @include noselect;
              width: 61px;
              height: 30px;
              border: 1px solid #cecece;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 500;
              cursor: pointer;
              &.sure {
                margin-left: 12px;
                background-color: #4587ff;
                color: #ffffff;
              }
              &.sure.disable {
                background-color: #fff;
                color: #999999;
              }
              &.cancel {
                background-color: #fff;
                color: #4587ff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
