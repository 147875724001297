const getParams = params => {
  const arr = [];
  for(const key in params){
    if(params){
      arr.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]));
    }
  }
  return arr.join("&");
}

const ajax = function(options){
  const xhr = new XMLHttpRequest();
  options = options || {};
  options.method = (options.method || "GET").toUpperCase();
  options.dataType = options.dataType || 'json';
  xhr.async = options.async || true;
  const params = options.params ? getParams(options.params) : "";
  if (options.method == 'GET'){
    xhr.open("GET",options.url + (Object.keys(params).length ? '?' + params : "") ,options.async);
    xhr.send(null)
  }else if (options.method == 'POST'){
    xhr.open('POST',options.url,options.async);
    xhr.setRequestHeader('Content-Type','application/x-www-form-urlencoded');
    xhr.send(options.data);
  }
}

export default ajax;