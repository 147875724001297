const disableContextmenu = {
  methods : {
    menuHandler(e){
      return e.preventDefault();
    }
  },
  created() {
    document.addEventListener("contextmenu",this.menuHandler);
  },
  beforeDestroy(){
    document.removeEventListener("contextmenu",this.menuHandler);
  }
}

export default disableContextmenu;