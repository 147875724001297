import crewordRequest from "./crewordRequest";

//网页采集
export function gather(data) {
  return crewordRequest({
    url: "/acquisition/gather",
    method: "post",
    data,
  });
}

//获取收藏列表
export function list(data) {
  return crewordRequest({
    url: "/datacollection/list",
    method: "post",
    data,
  });
}

//保存快捷方式列表
export function save(data) {
  return crewordRequest({
    url: "/datacollection/save",
    method: "post",
    data,
  });
}

//删除
export function del(data) {
  return crewordRequest({
    url: "/datacollection/delete",
    method: "post",
    data,
  });
}
